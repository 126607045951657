import { MINIMUM_PURCHASE_DELIVERY_FREE } from 'constants/configs';

export const DELIVERY_FREE = 'DELIVERY_FREE';
export const DELIVERY_PAY = 'DELIVERY_PAY';
export const DELIVERY_FREE_PAY = 'DELIVERY_FREE_PAY';

export const DELIVERY_FREE_MESSAGE = 'Gratis';
export const DELIVERY_PAY_MESSAGE = 'A abonar contra entrega';
export const NO_DELIVERY_METHOD_MESSAGE = 'No seleccionó un método de envío';

export const getShippingText = (deliveryMethod, subtotal) => {
  switch (deliveryMethod) {
    case DELIVERY_FREE:
      return DELIVERY_FREE_MESSAGE;
    case DELIVERY_PAY:
      return DELIVERY_PAY_MESSAGE;
    case DELIVERY_FREE_PAY:
      if (subtotal >= MINIMUM_PURCHASE_DELIVERY_FREE) {
        return DELIVERY_FREE_MESSAGE;
      }
      return DELIVERY_PAY_MESSAGE;
    default:
      return NO_DELIVERY_METHOD_MESSAGE;
  }
};
