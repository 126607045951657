import React from 'react';

import Logo from 'components/main/Header/Logo';
import Button from 'components/commons/Button';
import successImage from './assets/success.png';

import './SuccessfulPurchase.scss';

const SuccessfulPurchase = () => (
  <div className="container" data-testid="successful-purchase-page">
    <div className="row successful-purchase flex-column justify-content-center align-items-center text-center">
      <img src={successImage} alt="successful-purchase" className="successful-purchase-check" />
      <p className="successful-purchase-title">¡MUCHAS GRACIAS!</p>
      <p className="successful-purchase-title">¡Tu pedido fue realizado con &eacute;xito!</p>
      <p>
        <span>
          En pocos minutos se le estará enviando un mail a tu casilla de correo electr&oacute;nico
          {' '}
          con el detalle de tu pedido. Recordá verificar la carpeta de “SPAM” o
          “correo no deseado”.
        </span>
        <br />
        <span>
          En breve nos estaremos comunicando con vos para coordinar forma de pago y env&iacute;o.
        </span>
      </p>
      <p className="successful-purchase-title">Gracias por confiar en nosotros.</p>
      <div className="successful-purchase-logo">
        <Logo />
      </div>
      <Button text="Volver al inicio" href="/home" />
    </div>
  </div>
);

export default SuccessfulPurchase;
