import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Zoom from 'react-medium-image-zoom';

import { productPropTypes } from 'helpers/propTypes';
import { getCookie, setCookie } from 'helpers/cookies';
import { setVisitsProducts } from 'services/user.services';

import DescriptionItem from 'components/commons/DescriptionItem';
import QuantitySelector from 'components/commons/QuantitySelector';
import heartEmpty from '../../components/commons/Item/assets/heartEmpty.svg';
import heartFull from '../../components/commons/Item/assets/heartFull.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-medium-image-zoom/dist/styles.css';
import './Product.scss';
import remera from "./assets/remera.png";
import box from "./assets/box.png";

const Product = (props) => {
  const {
    handleColorSelect, images, item, onProductClick, onProductCopyClick, onQuantityChange,
    singleProductFullUrl, cartItemDescriptions, colors, combinations, wholesale,
    setWholesale, productCart, productCartSub, cartItemSubDescriptions,
    updateFavouriteItems, favouriteItem,
  } = props;

  const quantity = productCart ? productCart.quantity : (productCartSub ? productCartSub.quantity : 0); 
  const findProductWholesale = (id) => {
    const product = item.product_wholesaler.find((prod) => prod.id === id);
    return product.price * product.quantity;
  } 
  const otherProducts = cartItemDescriptions.filter((data) => (data.wholesale.id !== wholesale.id));
  const otherProductsSub = cartItemSubDescriptions.filter((data) => (data.wholesale.id  !== wholesale.id));
  useEffect(() => {
    updateCookie();
  });

  const updateCookie = async () => {
    try {
      if (getCookie('visit_product')) {
        const arrCount = getCookie('visit_product');
        addProduct(arrCount);
      } else {
        const arrCount = [];
        addProduct(arrCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addProduct = async (arrCount) => {
    const existProduct = arrCount.includes(item.id);
    if (!existProduct) {
      arrCount.push(item.id);
      setCookie('visit_product', arrCount, { maxAge: 86400 });
      const data = {
        id: item.id,
      };
      await setVisitsProducts(data);
    }
  };

  const renderProductQuantitySelector = () => {
    if (item.stock_product_descriptions.length > 0) {
      return (
        <ul className="product-details-stock">
          {Object.keys(combinations)?.map((id) => {
            const color = colors.find(({ stock_description_id }) => stock_description_id === Number(id));
            const descriptions = combinations[id];
            return (
              <div className="product-details-stock-option" key={`${item.id}-${id}-${wholesale.id}`}>
                <li className="clearfix">
                  <DescriptionItem
                    description={color}
                    handleColorSelect = {handleColorSelect}
                    onQuantityChange={onQuantityChange}
                    showQuantitySelector={descriptions.length === 0}
                    storage={cartItemDescriptions.find((data) => (data.descriptionId === color.id && data.wholesale.id === wholesale.id ))}
                    storageSub={cartItemSubDescriptions.find((data) => (data.descriptionId === color.id && data.wholesale.id === wholesale.id))}
                    othersWholesale={cartItemDescriptions.filter((data) => (data.descriptionId === color.id && data.wholesale.id !== wholesale.id))}
                    othersWholesaleSub={cartItemSubDescriptions.filter((data) => (data.descriptionId === color.id && data.wholesale.id !== wholesale.id))}
                  />
                </li>
                {descriptions.map((description) => (
                  <Fragment key={`${item.id}-${description.id}-${wholesale.id}`}>
                    {((description.stock > 0 && !wholesale.bool) || (description.stock >= wholesale.quantity && wholesale.bool)) && (
                      <li className="float-xs-left">
                        <DescriptionItem
                          description={description}
                          onQuantityChange={onQuantityChange}
                          storage={cartItemDescriptions.find((data) => (data.descriptionId === description.id && data.wholesale.id === wholesale.id))}
                          storageSub={cartItemSubDescriptions.find((data) => (data.descriptionId === description.id && data.wholesale.id === wholesale.id))}
                          othersWholesale={cartItemDescriptions.filter((data) => (data.descriptionId === description.id && data.wholesale.id !== wholesale.id))}
                          othersWholesaleSub={cartItemSubDescriptions.filter((data) => (data.descriptionId === description.id && data.wholesale.id !== wholesale.id))}
                        />
                      </li>
                    )}
                  </Fragment>
                ))}
              </div>
            );
          })}
        </ul>
      );
    }
    if (item.stock > 0) {
      return (
        <Fragment key={`${item.id}-${wholesale.bool}`}>
          <QuantitySelector
            limit={item.stock}
            onQuantityChange={onQuantityChange}
            quantity={quantity}
            quantityOthersWholesale={otherProducts.map((otherProduct) => (otherProduct.quantity))}
          />
        </Fragment>
      );
    }

    return <span className="product-details-out-of-stock">Sin stock</span>;
  };

  return (
    <div data-testid="product-modal">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="product-details-image-container">
              <div className="fav-container">
                <span className="product-fav" onClick={() => updateFavouriteItems(item.id, favouriteItem)} role="presentation">
                  { favouriteItem ? (
                    <img src={heartFull} className="image-heart-favourite-fill" alt="" />
                  ) : (
                    <div className="heart-display">
                      <img src={heartEmpty} className="image-heart-favourite-empty" alt="" />
                      <img src={heartFull} className="image-heart-favourite-fill" alt="" />
                    </div>
                  ) }
                  {' '}

                </span>
              </div>
              <Carousel
                autoPlay
                infiniteLoop
                showIndicators={false}
                showStatus={false}
                showThumbs
                swipeable={false}
              >
                {images.map((image, index) => (
                  <Zoom key={`${image.id}-${index}`}>
                    <img alt={item.name} src={image} width="250" />
                  </Zoom>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="product-details">
              <div>
                <div>
                  <span
                    className="product-details-name"
                    onClick={onProductClick}
                    role="presentation"
                  >
                    {item.name}
                  </span>
                  <CopyToClipboard
                    text={singleProductFullUrl}
                    onCopy={onProductCopyClick}
                  >
                    <i
                      className="fa fa-clone product-details-copy-icon"
                      aria-hidden="true"
                      title="Copiar link del producto"
                    />
                  </CopyToClipboard>
                </div>
                <div className="container-mayorista">
                  { 
                    item.product_wholesaler.length > 0 ? (
                      <>
                        <button onClick={() => setWholesale({id: 0, bool: false, quantity: 0})} role="presentation" className={!wholesale.bool ? 'circular-button-selected circular-button' : 'circular-button'}>
                          <img className={`image-product-single-mayorista ${!wholesale.bool && 'animacion-selected'}`} src={remera} />
                          <span className={`button-text ${!wholesale.bool && 'animacion-selected'}`} >
                            Precio estandar
                          </span>
                        </button>
                        {item.product_wholesaler.map((prod, index) => (
                          <>
                            <button onClick={() => setWholesale({id: prod.id, bool: true, quantity: prod.quantity})} role="presentation" className={(wholesale.bool && wholesale.id == prod.id) ? 'circular-button-selected circular-button' : 'circular-button'}>
                              <img className={`image-product-single-mayorista ${(wholesale.bool && wholesale.id == prod.id) && 'animacion-selected'}`} src={box} />
                              <span className={`button-text ${(wholesale.bool && wholesale.id == prod.id) && 'animacion-selected'}`} >
                                Precio por {prod.quantity} 
                              </span> 
                            </button>
                          </>
                        ))}
                      </>
                    )
                    : (
                      <>
                        <button role="presentation" className='circular-button'>
                          <img className="image-product-single-mayorista animacion-selected" src={remera} />
                          <span className="button-text animacion-selected" >
                            Precio estandar
                          </span>
                        </button>
                      </>
                    )
                  }
                  </div>

              </div>
              { !wholesale.bool && item.sale ? <div style={{ color: 'red' }}>{` ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</div> : <div />}
              <div className="product-details-section">
                { (item.product_wholesaler.length > 0) ? 
                    (wholesale.bool ?
                      (<div className="product-details-price">
                        {`$${(findProductWholesale(wholesale.id)).toLocaleString('de-DE')}`}
                      </div>) : 
                      (<div className="product-details-price">
                        {`$${item.price.toLocaleString('de-DE')}`}
                      </div>)
                    )
                : 
                  <div className="product-details-price">
                    {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                    {!!item.sale && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}
                  </div>
                }
              </div>
              <div className="separator" />
              <div className="product-details-section">
                {renderProductQuantitySelector()}
              </div>
                <div className="product-details-section">
                  <span className="product-details-description"><pre>{item.description}</pre></span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Product.defaultProps = {
  wholesale: false,
};

Product.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  cartItemDescriptions: PropTypes.array.isRequired,
  cartItemSubDescriptions: PropTypes.array.isRequired,
  item: productPropTypes.isRequired,
  onProductClick: PropTypes.func.isRequired,
  onProductCopyClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  singleProductFullUrl: PropTypes.string.isRequired,
  wholesale: PropTypes.bool,
  setWholesale: PropTypes.func.isRequired,
  productCart: PropTypes.shape({
    quantity: PropTypes.number,
  }),
  productCartSub: PropTypes.shape({
    quantity: PropTypes.number,
  }),
  updateFavouriteItems: PropTypes.func.isRequired,
  favouriteItem: PropTypes.bool.isRequired,
};

export default Product;
