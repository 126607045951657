/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { connect } from 'react-redux';

const ScriptContainer = (props) => {
  const { scripts } = props;
  useEffect(() => {
    scripts.forEach((script) => {
      const scriptContainer = document.createElement('div');
      scriptContainer.innerHTML = script.text;

      Array.from(scriptContainer.childNodes).forEach((scriptNode) => {
        document.head.appendChild(scriptNode);
      });
    });
  }, [scripts]);
  return null;
};
const mapStateToProps = (state) => ({
  scripts: state.app.dataAnalytics,
});

export default connect(mapStateToProps)(ScriptContainer);
