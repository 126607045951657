import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { productPropTypes } from 'helpers/propTypes';
import QuantitySelector from 'components/commons/QuantitySelector';
import heartEmpty from './assets/heartEmpty.svg';
import heartFull from './assets/heartFull.svg';
import './Item.scss';

const Item = (props) => {
  const {
    item, onItemClick, onQuantityChange, quantity, updateFavouriteItems, favouriteItem, colors, talles, combinations, combinationsTalle,
  } = props;

  const [selectedColor, setSelectedColor] = useState(null);
  const [tallesXColor, setTallesXColor] = useState(null);
  const handleColorSelect = (color, descriptions) => {
    setSelectedColor(color);
    setTallesXColor(descriptions);
  };

  const otherImage = item.image1;
  const selectedColorImage =  selectedColor ? (selectedColor?.image_2 ? selectedColor.image_2: selectedColor.image_1) : item.image1;
  const imageSource = selectedColor ? (selectedColorImage ? selectedColorImage:item.image1) : item.image1;

  const renderProductButton = () => {
    if (item.product_wholesaler.length > 0) {
      return (
          <button type="button" onClick={onItemClick}>
            Ver Combos
          </button>
      );
    }

    if (item.stock_product_descriptions.length > 0) {
      return (
          <button type="button" onClick={onItemClick}>
            Ver detalles
          </button>
      );
    }

    if (item.stock > 0) {
      return (
        <div className="product-quantity-selector">
          <QuantitySelector
            limit={item.stock}
            onQuantityChange={onQuantityChange}
            quantity={quantity}
          />
        </div>
      );
    }

    return <span className="product-out-of-stock">Sin stock</span>;
  };

  return (
    <div className="product-item" data-testid={`product-${item.id}`}>
      <div className="product">
        <div className="product-image">
          <div className="product-image-icons">
            <img src={imageSource} alt={item.name} onClick={onItemClick} role="presentation" />
            {renderProductButton()}
            <div className="favorito-heart" onClick={() => updateFavouriteItems(item.id, favouriteItem)} role="presentation">
              { favouriteItem ? (
                <img src={heartFull} className="image-heart-favourite-fill" alt="" />
              ) : (
                <div className="heart-display">
                  <img src={heartEmpty} className="image-heart-favourite-empty" alt="" />
                  <img src={heartFull} className="image-heart-favourite-fill" alt="" />
                </div>
              ) }
            </div>
          </div>
        </div>
        <div className="product-info">
          <h6 className="product-name" onClick={onItemClick} role="presentation">
            {item.name}
            <div className="product-price">
                  <>
                    {item.sale ? <div style={{ color: 'red' }}>{` ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</div> : <div />}
                    {item.product_wholesaler.length > 0 > 0 ? (<span style={{color: "black"}}>Combos Disponibles!</span>) :<></>}
                    <br/>
                    {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                  </>
            </div>
          </h6>
          <div className={item.sale ? 'combinacionesOferta' : 'combinaciones'}>
          {Object.values(combinations).some(array => array.length > 0) && (
          <div className="color-selector">
            {Object.keys(combinations)?.map((id) => {
              const color = colors.find(({ stock_description_id }) => stock_description_id === Number(id));
              const descriptions = combinations[id];
              const isMulticolor = color.stock_descriptions.name === "Multicolor";
              return (
                <div
                  key={id}
                  className={`color-circle ${isMulticolor ? 'multicolor' : ''}`}
                  style={isMulticolor ? {} : { backgroundColor: color.stock_descriptions.rgb }}
                  onClick={() => handleColorSelect(color, descriptions)}
                />
              );
            })}
          </div>
        )}

        <div className="color-selector">
          { talles.length > 0 && (
          <>
            Talles:&nbsp;
          </>
          )}
          { !tallesXColor ? (Object.keys(combinationsTalle)?.map((id) => {
            const talle = talles.find(({ stock_description_id }) => stock_description_id === Number(id));
            return (
              <span>{talle.stock_descriptions.name}</span>
            );
          })) : (
            tallesXColor.map((talle) =>               
              <span>{talle.stock_descriptions.name}</span>
            )
          )}
        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

Item.propTypes = {
  item: productPropTypes.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default Item;
