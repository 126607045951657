import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as constants from 'redux/constants/modal.constants';
import { closeModal } from 'redux/actions/modal.actions';

import LoginContainer from 'containers/LoginContainer';
import RegisterContainer from 'containers/RegisterContainer';
import ResetPasswordContainer from 'containers/ResetPasswordContainer';
import SearchContainer from 'containers/SearchContainer';
import ProductContainer from 'containers/ProductContainer';
import Modal from 'components/commons/Modal';

const ModalManager = (props) => {
  const { active, data, onClose } = props;
  switch (active) {
    case constants.MODAL_LOGIN:
      return <LoginContainer {...props} />;
    case constants.MODAL_REGISTER:
      return <RegisterContainer {...props} />;
    case constants.MODAL_RESET_PASSWORD:
      return <ResetPasswordContainer {...props} />;
    case constants.MODAL_SEARCH:
      return <SearchContainer {...props} />;
    case constants.MODAL_PRODUCT:
      return (
        <Modal isLarge onClose={onClose}>
          <ProductContainer {...props} item={data} />
        </Modal>
      );
    default:
      return null;
  }
};

const mapStateToProps = (state) => ({
  active: state.modal.active,
  data: state.modal.data,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: bindActionCreators(closeModal, dispatch),
});

ModalManager.defaultProps = {
  active: null,
  data: null,
};

ModalManager.propTypes = {
  active: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  onClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalManager);
