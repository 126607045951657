import { createSelector } from 'reselect';

export const getProducts = (state) => state.product.items;
const getCode = (state, props) => props.match.params.product;
const getItem = (state, props) => props.item;
const getUrl = (state, props) => props.match.url;
export const getSearch = (state, props) => props.match.params.search;
const removeAccents = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const productsUrlSelector = createSelector([getUrl], (url) => url);

export const productSelector = createSelector([getProducts, getCode], (products, code) => (
  products.find((product) => product.code.replaceAll('%', '') === code.replaceAll('%', ''), null)
));

export const searchProductsSelector = createSelector([getProducts, getSearch],
  (products, search) => products.filter((product) => {
    const name = (removeAccents(product.name));
    const description = removeAccents(product.description.toLowerCase());

    const matchesName = name.toLowerCase().indexOf(search) !== -1;
    const matchesDescription = description.toLowerCase().indexOf(search) !== -1;

    let tags = false;
    if (!!product.tags.length > 0) {
      tags = product.tags.some((element) => element.name.toString().includes(search));
    }
    return matchesName || matchesDescription || tags;
  }, []));

export const productImagesSelector = createSelector([getItem],
  (product) => {
    const images = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 6; i++) {
      const image = product[`image${i}`];
      if (image) {
        images.push(image);
      }
    }
    return images;
  });
