import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Avatar from '@material-ui/core/Avatar';
import Person from '@material-ui/icons/Person';

import Loading from 'components/commons/Loading';
import Input from 'components/commons/Input';
import Button from 'components/commons/Button';

import './ResetPasswordForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección de email inválida';
  }
  return errors;
};

const ResetPasswordForm = (props) => {
  const {
    handleSubmit, loading, onSubmit, submitting,
  } = props;

  return (
    <div className="container" data-testid="reset-password-form">
      <div className="row">
        {loading && <Loading message="Cargando..." size={80} />}
        <div className={`form-container ${loading ? 'd-none' : ''}`}>
          <Avatar className="avatar">
            <Person />
          </Avatar>
          <h4>Reiniciar contraseña</h4>
          <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
            <Field
              component={Input}
              label="Email"
              name="email"
            />
            <div className="submit-button-container">
              <Button text="Enviar" type="submit" buttonClass="btn-block" disabled={submitting} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'resetPassword',
  validate,
})(ResetPasswordForm);
