import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { productPropTypes } from 'helpers/propTypes';
import { productSelector } from 'redux/selectors/product.selector';

import ProductContainer from 'containers/ProductContainer';
import Loading from 'components/commons/Loading';

const ProductSingleContainer = (props) => {
  const { item, itemsCount } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (item) {
      setLoading(false);
    }
  }, [item, itemsCount]);

  return (
    <>
      <br />
      <br />
      {loading ? <Loading /> : <ProductContainer item={item} />}
      <br />
      <br />
    </>
  );
};

const mapStateToProps = (state, props) => ({
  item: productSelector(state, props),
  itemsCount: state.product.items.length,
});

ProductSingleContainer.defaultProps = {
  item: null,
};

ProductSingleContainer.propTypes = {
  item: productPropTypes,
  itemsCount: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(ProductSingleContainer);
