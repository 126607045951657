import { createSelector } from 'reselect';
import { getProducts } from 'redux/selectors/product.selector';

const getFavouriteItems = (state) => state.favourite.items;
const getItem = (state, props) => props.item;

export const itemFavouriteSelector = createSelector([getFavouriteItems, getItem],
  (favouriteItems, item) => (
    favouriteItems.some((favouriteItem) => favouriteItem === item.id)
  ));

export const favouriteSizeSelector = createSelector([getFavouriteItems], (items) => (
  items.length
));

export const favouriteItemsSelector = createSelector([getProducts, getFavouriteItems],
  (products, favouriteItems) => {
    const items = [];
    favouriteItems.forEach((favouriteItem) => {
      const item = products.find((product) => product.id === favouriteItem, null);
      if (item) {
        items.push(item);
      }
    });
    return items;
  });
