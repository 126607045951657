import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { cartItemsArrayPropTypes } from 'helpers/propTypes';
import { getLocalStorage } from 'helpers/storage';

import Breadcrumb from 'components/commons/Breadcrumb';
import DescriptionItem from 'components/commons/DescriptionItem';
import QuantitySelector from 'components/commons/QuantitySelector';
import Button from 'components/commons/Button';
import Benefit from 'components/commons/Benefit';

import './Cart.scss';

const Cart = (props) => {
  const {
    cartSize, items, minimumPurchase, onQuantityChange, onRemoveFromCart, subtotal,
    priceDiscount, onItemClick,
  } = props;
  const showCart = cartSize > 0;

  const renderEmptyCart = () => (
    <h2 className="text-center" data-testid="empty-cart">
      El carrito está vacío
    </h2>
  );

  const findProductWholesale = (id, item) => {
    const product = item.product_wholesaler.find((prod) => prod.id === id);
    return product.price * product.quantity;
  };

  const findIDProductWholesale = (subItem) => {
    const values = Object.values(subItem);
    const firstArray = values[0];
    const firstObject = firstArray[0];
    const { id } = firstObject.wholesale;
    if (id > 0) {
      return `Precio por ${firstObject.wholesale.quantity}`;
    }
    return 'Precio Mayorista';
  };

  const findPriceProductWholesale = (subItem, item) => {
    const values = Object.values(subItem);
    const firstArray = values[0];
    const firstObject = firstArray[0];
    const { id } = firstObject.wholesale;
    if (id > 0) {
      const product = item.product_wholesaler.find((prod) => prod.id === id);
      return product.price * product.quantity;
    }
    return item.sale ? item.sale : item.price;
  };
  const renderDescriptions = (descriptions, colors, item, descriptionId) => {
    const color = colors.find(
      ({ stock_description_id }) => stock_description_id === Number(descriptionId),
    );
    const combinations = descriptions;
    return !!combinations.length && (
    <div className="cart-item-description-option">
      {combinations.map((combination, index) => (
        <Fragment key={`${item.id}-${combination.description.id}-${combination.wholesale.id}`}>
          {index === 0 && (
          <div className="clearfix">
            <DescriptionItem
              description={color}
              onQuantityChange={(value, desc) => {
                onQuantityChange(item, value, desc, combination.wholesale);
              }}
              showQuantitySelector={combination.description.id === color.id}
              storage={{ quantity: combination.quantity }}
            />
          </div>
          )}
          {combination.description.id !== color.id && (
          <div className="float-xs-left" key={`${item.id}-${combination.description.id}-${combination.wholesale.id}`}>
            <DescriptionItem
              description={combination.description}
              onQuantityChange={(value, desc) => {
                onQuantityChange(item, value, desc, combination.wholesale);
              }}
              storage={{ quantity: combination.quantity }}
              othersWholesale={combination.othersSubItemsWholesale}
            />
          </div>
          )}
        </Fragment>
      ))}
    </div>
    );
  };
  const renderFullCart = () => (
    <div className="cart" data-testid="full-cart">
      <h2 className="text-center">
        {`Hay ${cartSize} producto${cartSize > 1 ? 's' : ''}`}
      </h2>
      <div className="cart-items">
        <div className="separator" />
        {items.map(({
          item, quantity, colors, subItems, subItemsDescriptions,
        }) => !!item && (
          <div className="cart-item" key={`cart-item-${item.id}`}>
            <div className="d-flex">
              <Button
                text={<i className="fa fa-remove" />}
                buttonClass="cart-item-remove"
                onClick={() => onRemoveFromCart(item)}
              />
            </div>
            <div className="row">
              <div className="col-md-4 d-flex align-items-center justify-content-center">
                <img className="cursor-pointer" onClick={() => onItemClick(item)} src={item.image1} alt={item.name} role="presentation" />
              </div>
              <div className="col-md-8">
                <ul className="cart-item-info">
                  <li className="d-flex flex-row">
                    <span className="cart-item-title cursor-pointer" onClick={() => onItemClick(item)} role="presentation">
                      {item.name}
                    </span>
                  </li>
                  <li>
                    <span className="cart-item-description">
                      {item.description}
                    </span>
                  </li>
                  <li>
                    { !(item.product_wholesaler.length > 0)
                    && (item.stock_product_descriptions.length === 0) && (
                    <>
                      <span className="cart-item-price">
                        {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                        {!!item.sale && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}
                      </span>
                      <QuantitySelector
                        limit={item.stock}
                        onQuantityChange={(value) => onQuantityChange(item, value, null, {
                          id: 0, bool: false, quantity: 0,
                        })}
                        quantity={quantity}
                      />
                    </>
                    )}
                  </li>
                  <li className="descriptions-col">
                    {colors && (
                      <div className="descriptions-container">
                        {Object.values(subItemsDescriptions).some((value) => typeof value === 'object' && Object.keys(value).length > 0)
                          && (
                          <div className="precios-container-wrapper">
                            {Object.values(subItemsDescriptions).map((subItemDescription) => (
                              <>
                                { Object.keys(subItemDescription).length > 0
                              && (
                              <div className="precios-container">
                                <span className="cart-item-price">
                                  {!!item.sale && (item.product_wholesaler.length === 0) && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}
                                  {`$${(findPriceProductWholesale(subItemDescription, item)).toLocaleString('de-DE')}`}
                                </span>
                                <span className="title-description search-item">{findIDProductWholesale(subItemDescription)}</span>
                                {Object.entries(subItemDescription).map(([descriptionId, descriptionArray]) => renderDescriptions(descriptionArray, colors, item, descriptionId))}
                              </div>
                              )}
                              </>
                            ))}
                          </div>
                          )}
                      </div>
                    )}
                    {
                      subItems?.length > 0 && (
                        <div className="precios-container-wrapper">
                          {subItems.map((subItem) => (
                            <div className="precios-container">
                              <span className="cart-item-price">
                                {`$${(subItem.wholesale.id > 0 ? (findProductWholesale(subItem.wholesale.id, item)) : item.price).toLocaleString('de-DE')}`}
                              </span>
                              <span className="title-description search-item">
                                { subItem.wholesale.id > 0 ? (`Precio por ${subItem.wholesale.quantity}`) : ('Precio Mayorista')}
                              </span>
                              <QuantitySelector
                                limit={item.stock}
                                onQuantityChange={(value) => onQuantityChange(item, value, null, subItem.wholesale)}
                                quantity={subItem.quantity}
                                quantityOthersWholesale={subItems.filter((otherProduct) => otherProduct.wholesale.id !== subItem.wholesale.id).map((otherProduct) => (otherProduct.quantity))}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    }
                  </li>
                </ul>
              </div>
            </div>
            <div className="separator" />
          </div>
        ))}
      </div>
    </div>
  );

  const renderCheckoutDetails = () => (
    <div className="row" data-testid="checkout-details">
      <div className="col">
        <div className="checkout">
          <ul className="checkout-details">
            <li>
              Subtotal:
              {priceDiscount === null ? (
                <span className="float-right">
                  {`$${subtotal.toLocaleString('de-DE')}`}
                </span>
              ) : (
                <del className="float-right">
                  {`$${subtotal.toLocaleString('de-DE')}`}
                </del>
              )}
            </li>
            {priceDiscount != null ? (
              <li>
                Con descuento
                {' '}
                {getLocalStorage('percentage')}
                %:
                <span className="float-right">
                  {`$${priceDiscount.toLocaleString('de-DE')}`}
                </span>
              </li>
            ) : ''}
          </ul>
          <div className="row">
            <div className="col-lg-6">
              <Button text="Envío a domicilio" buttonClass="checkout-button" href="/envio-a-domicilio" />
            </div>
            <div className="col-lg-6">
              <Button text="Retiro en Sucursal Lenceria Maguen" buttonClass="checkout-button" href="/retiro-en-sucursal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div data-testid="page-cart">
      <div className="container">
        <div className="row">
          <div className="col clearfix">
            <Breadcrumb urls={['/carrito']} names={['Carrito']} />
            <div className="row">
              <div className="col">
                {!showCart && renderEmptyCart()}
                {showCart && renderFullCart()}
                {showCart && renderCheckoutDetails()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <Benefit minimumPurchase={minimumPurchase} />
      </div>
    </div>
  );
};

Cart.defaultProps = {
  priceDiscount: null,
};

Cart.propTypes = {
  cartSize: PropTypes.number.isRequired,
  items: cartItemsArrayPropTypes.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  onRemoveFromCart: PropTypes.func.isRequired,
  subtotal: PropTypes.number.isRequired,
  onItemClick: PropTypes.func.isRequired,
  priceDiscount: PropTypes.number,
};

export default Cart;
