import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { orderPropTypes } from 'helpers/propTypes';

import {
  TableRow, TableCell, IconButton, Collapse, Box, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import CustomTable from 'components/commons/CustomTable';
import Button from 'components/commons/Button';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const PurchaseHistoryRow = (props) => {
  const {
    onRepeatAllProductsClick, onRepeatProductClick, purchase,
    onDownloadOrderPDF,
  } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  const KeyboardArrowIcon = open ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

  return (
    <>
      <TableRow className={classes.root} data-testid="purchase-row">
        <TableCell>
          <IconButton
            aria-label="expand row"
            data-testid="icon-button"
            onClick={() => setOpen(!open)}
            size="small"
          >
            <KeyboardArrowIcon color="disabled" />
          </IconButton>
        </TableCell>
        <TableCell>{purchase.orderId}</TableCell>
        <TableCell>{purchase.date}</TableCell>
        <TableCell>{purchase.paymentMethod}</TableCell>
        <TableCell>{purchase.state}</TableCell>
        <TableCell>
          $
          {purchase.total}
        </TableCell>
        <TableCell>
          <Button
            onClick={() => onDownloadOrderPDF(purchase.orderId)}
            text={<i className="fa fa-download" aria-hidden="true" />}
            type="button"
          />
        </TableCell>
        <TableCell align="right">
          <Button
            onClick={() => onRepeatAllProductsClick(purchase)}
            text="Repetir todo el pedido"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Productos
              </Typography>
              <CustomTable
                renderRow={(product) => (
                  <TableRow key={product.id}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>
                      $
                      {product.price}
                    </TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell>
                      $
                      {Math.round(product.quantity * product.price * 100) / 100}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        disabled={product.quantity > product.stock}
                        onClick={() => onRepeatProductClick(product)}
                        text="Repetir producto"
                      />
                    </TableCell>
                  </TableRow>
                )}
                rows={purchase.products}
                size="small"
                titles={['Producto', 'Precio unitario', 'Cantidad', 'Total', '']}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

PurchaseHistoryRow.propTypes = {
  onRepeatAllProductsClick: PropTypes.func.isRequired,
  onRepeatProductClick: PropTypes.func.isRequired,
  onDownloadOrderPDF: PropTypes.func.isRequired,
  purchase: orderPropTypes.isRequired,
};

export default PurchaseHistoryRow;
