import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { userPropTypes } from 'helpers/propTypes';
import { updateProfile, changePassword } from 'redux/actions/user.actions';

import ProfileView from 'views/Profile';

const ProfileContainer = (props) => {
  const {
    changePasswordAction, loading, minimumPurchase, updateProfileAction, user,
  } = props;

  return (
    <ProfileView
      changePassword={changePasswordAction}
      loading={loading}
      minimumPurchase={minimumPurchase}
      updateProfile={updateProfileAction}
      user={user}
    />
  );
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  minimumPurchase: state.app.minimumPurchase,
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  changePasswordAction: bindActionCreators(changePassword, dispatch),
  updateProfileAction: bindActionCreators(updateProfile, dispatch),
});

ProfileContainer.defaultProps = {
  user: null,
};

ProfileContainer.propTypes = {
  changePasswordAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  updateProfileAction: PropTypes.func.isRequired,
  user: userPropTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
