import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { resetPassword } from 'redux/actions/user.actions';

import Modal from 'components/commons/Modal';
import ResetPasswordForm from 'components/forms/ResetPasswordForm';

const ResetPasswordContainer = (props) => {
  const {
    loading, onClose, resetPasswordAction,
  } = props;

  return (
    <Modal loading={loading} onClose={onClose}>
      <ResetPasswordForm
        loading={loading}
        onSubmit={resetPasswordAction}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
});

const mapDispatchToProps = (dispatch) => ({
  resetPasswordAction: bindActionCreators(resetPassword, dispatch),
});

ResetPasswordContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  resetPasswordAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
