import React from 'react';
import PropTypes from 'prop-types';

import {
  TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const CustomTable = (props) => {
  const {
    renderRow, rows, size, titles,
  } = props;

  return (
    <TableContainer component={Paper} data-testid="custom-table-component">
      <Table aria-label="table" size={size}>
        <TableHead>
          <TableRow>
            {titles.map((title, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <StyledTableCell key={`${title}-${index}`}>{title}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(renderRow)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CustomTable.defaultProps = {
  size: 'medium',
};

CustomTable.propTypes = {
  renderRow: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  size: PropTypes.string,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CustomTable;
