import { getLocalStorage } from 'helpers/storage';
import { post } from 'services/api';

export const delivery = async (data) => {
  data.colors = getLocalStorage('descriptionsCart') ?? [];
  const response = await post('checkout/delivery', data);
  return response;
};

export const pickup = async (data) => {
  data.colors = getLocalStorage('descriptionsCart') ?? [];
  const response = await post('checkout/pickup', data);
  return response;
};

export const validateCoupon = async (total, coupon, email) => {
  const response = await post('searchCoupon', { total, coupon, email });
  return response;
};
