import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Breadcrumb from 'components/commons/Breadcrumb';

import { productsArrayPropTypes } from 'helpers/propTypes';
import { favouriteItemsSelector } from 'redux/selectors/favourite.selector';
import Loading from 'components/commons/Loading';
import ItemContainer from './ItemContainer';

const FavouriteContainer = (props) => {
  const {
    items, loading,
  } = props;

  const renderItem = (item) => (
    <div className="col-product" style={{ background: 'rgb(249 249 249)' }} key={`item-${item.id}`}>
      <ItemContainer item={item} />
    </div>
  );
  return (
    <div className="container fix-space">
      <div className="row">
        <div className="col clearfix">
          <Breadcrumb urls={['/favoritos']} names={['Productos favoritos']} />
          <div className="row align-items-center product-slider-container" data-testid="carousel">
            {loading && <Loading />}
            {!loading && (
            <>
              {!items.length && (
              <div className="no-realizo carrito-empty">
                <span data-testid="empty-cart">
                  No se encontraron productos
                </span>
              </div>
              )}
              {!!items.length && items.map((item) => renderItem(item))}
            </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  items: favouriteItemsSelector(state),
  loading: state.user.loading,
});

FavouriteContainer.propTypes = {
  items: productsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(FavouriteContainer);
