import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from 'components/commons/Loading';

import './Preloader.scss';

const Preloader = (props) => {
  const { showPreloader } = props;

  useEffect(() => {
    document.body.style.overflow = showPreloader ? 'hidden' : 'auto';
  }, [showPreloader]);

  return (
    <div className={`preloader ${showPreloader ? '' : 'hide'}`} data-testid="preloader">
      <Loading size={80} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  showPreloader: state.app.showPreloader,
});

Preloader.propTypes = {
  showPreloader: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Preloader);
