import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import ScrollMenu from 'react-horizontal-scroll-menu';
import imgInfo from './assets/imagen1.png';

import { productsArrayPropTypes, slidersArrayPropTypes } from 'helpers/propTypes';
import { sectionsArray } from 'views/Home/constants';

import Benefit from 'components/commons/Benefit';
import Loading from 'components/commons/Loading';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Home.scss';

const Home = (props) => {
  const {
    categories, categoriesHome, currentSection, items, loading, minimumPurchase, onSectionClick, renderItem, sliders, offers,
  } = props;
  const [mapa1, setmapa1] = useState(true);
  const [mapa2, setmapa2] = useState(false);
  const [mapa3, setmapa3] = useState(false);

  const cambiarmapa1 = () =>{
    setmapa1(true);
    setmapa2(false);
    setmapa3(false);
  };
  const cambiarmapa2 = () =>{
    setmapa2(true);
    setmapa1(false);
    setmapa3(false);
  };
  const cambiarmapa3 = () =>{
    setmapa3(true);
    setmapa2(false);
    setmapa1(false);
  };
  const renderSilder = () => (
    <div data-testid="slider">
      <div className='carousel-button'>
      <Carousel
        autoPlay
        showStatus={false}
        showThumbs={false}
        swipeable={false}
      >
        {sliders.map(({
          id, image, text, isImage,
        }) => (
          <div className="main-slider" key={id}>
            {isImage ? (
              <img className="main-slider-img" src={image} alt={text} />
            ) : (
              <video autoPlay muted loop controls className="main-slider-video">
                <track kind="captions" srcLang="en" label="English_captions" />
                <source src={image} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        ))}
      </Carousel>
      <Link to="/productos"><button className='cbutton'>Ver más productos</button></Link>
    </div>
  </div>
  );
        
  return (
    <div data-testid="home-page">
      {renderSilder()}
      <div className="navbar-categories">
          <ScrollMenu
            alignCenter={false}
            arrowDisabledClass="scroll-menu-arrow--disabled"
            arrowLeft={<span className="arrow-prev">{' < '}</span>}
            arrowRight={<span className="arrow-next">{' > '}</span>}
            data={categories.map((category) => (
              <Link key={category.id} to={`/productos/${category.code}`}>
                {category.name}
              </Link>
            ))}
            dragging={false}
            hideArrows
            hideSingleArrow
            wheel={false}
          />
        </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="section">
              {categoriesHome.length ?
              <div className="category-images">
                <div className='row'>
                  <div className="col-lg-5">
                    <div className='img-button column-flex'>
                      <img src={categoriesHome[0].category.image}></img>
                      <Link to= {"/productos/" + categoriesHome[0].category.code}><button>{categoriesHome[0].category.name}</button></Link>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className='row'>
                      <div className='img-button column-flex'>
                        <img className='img-altura' src={categoriesHome[1].category.image} width={90}/>
                        <Link to= {"/productos/" + categoriesHome[1].category.code}><button>{categoriesHome[1].category.name}</button></Link>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-6 column-flex'>
                        <div className='img-button column-flex'>
                          <img src={categoriesHome[2].category.image}></img>
                          <Link to= {"/productos/" + categoriesHome[2].category.code}><button>{categoriesHome[2].category.name}</button></Link>
                        </div>
                      </div> 
                      <div className='col-lg-6 column-flex'>
                        <div className='img-button column-flex'>
                          <img src={categoriesHome[3].category.image}></img>
                          <Link to= {"/productos/" + categoriesHome[3].category.code}><button>{categoriesHome[3].category.name}</button></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :<></>}
            </div>
            <div className="section">
            <div className="row align-items-center">
                <div className="col text-center">
                  <div className="new-arrivals-sorting">
                    <ul className="arrivals-grid-sorting clearfix button-group filters-button-group">
                      {sectionsArray.map((section) => (
                        <button
                          className={`green-button ${section.value === currentSection.value ? 'active' : ''}`}
                          data-testid={`section-${section.value}`}
                          key={section.value}
                          onClick={() => onSectionClick(section.value)}
                          role="presentation"
                        >
                          {section.label}
                        </button>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row align-items-center product-slider-container" data-testid="carousel">
                {loading && <Loading />}
                {!loading && (
                  <>
                    {!items.length && <h4 className="text-center">No se encontraron productos</h4>}
                    {!!items.length && items.map((item) => renderItem(item))}
                  </>
                )}
              </div>
              <div className='row justify-content-center'>
                <Link to="/productos"><button type="button" className='green-button' >Ver todos los productos</button></Link>
              </div>
            </div>
            <div className="section">
              <div className="row align-items-center">
                <div className="col text-center info-container">
                  <a>Ofertas</a>
                </div>
              </div>
              <div className="row align-items-center product-slider-container" data-testid="carousel">
                {loading && <Loading />}
                {!loading && (
                  <>
                    {!offers.length && <h4 className="text-center">No se encontraron productos</h4>}
                    {!!offers.length && offers.map((item) => renderItem(item))}
                  </>
                )}
              </div>
              <div className='row justify-content-center'>
                <Link to='/ofertas'><button type="button" className='green-button' >Ver mas ofertas</button></Link>
              </div>
            </div>
            <div className="section">
            <div className="info-container">
              <ul>
                <li><span>Visitá nuestro local</span></li>
                <li><Link to="/contacto"><button type="button" className='green-button' >Cómo llegar</button></Link></li>
                <li><button type="button" className='green-button' onClick={cambiarmapa1} > Bogota 3188</button><button type="button" className='green-button' onClick={cambiarmapa2} >Av. Avellaneda 3493</button><button type="button" className='green-button' onClick={cambiarmapa3}  >Campana 398, Flores</button></li>
                <li>
                    <iframe className={mapa1? "maps-panoram":"mapsInv"} src="https://www.google.com/maps/embed?pb=!4v1704300961205!6m8!1m7!1suadgFciCxiVA8eKc3zx5wg!2m2!1d-34.62845406562562!2d-58.47485696192036!3f156.86989396224246!4f-1.234670521021883!5f0.7820865974627469" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <iframe className={mapa2? "maps-panoram":"mapsInv"} src="https://www.google.com/maps/embed?pb=!4v1704301093693!6m8!1m7!1s1MTxkzPkJcbZNQh3BIQphw!2m2!1d-34.62820297964998!2d-58.47860829619491!3f347.25827496950126!4f1.244800360433615!5f0.7820865974627469" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <iframe className={mapa3? "maps-panoram":"mapsInv"} src="https://www.google.com/maps/embed?pb=!4v1704301192574!6m8!1m7!1sOPv17z8iKVMe0Bsb1_CJlw!2m2!1d-34.62790760051305!2d-58.47651275438574!3f250.26422911660546!4f-0.29253617426353173!5f0.8115793154937668" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </li>
                <li>
                  <span>Seguínos en</span><br/>
                  <a href="https://instagram.com/lenceriamaguen?igshid=MmJiY2I4NDBkZg==" target="_blank" rel="noopener noreferrer">@lenceriamaguen</a>
                </li>
                <li><img src={imgInfo}/></li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  currentSection: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  items: productsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  onSectionClick: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  sliders: slidersArrayPropTypes.isRequired,
};

export default Home;
