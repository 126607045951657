import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import { bindActionCreators } from 'redux';
import { sendNewsletter } from 'redux/actions/user.actions';

import './Footer.scss';
import { connect } from 'react-redux';

const Footer = (props) => {
  const {sendNewsletterAction
  } = props;

  const [newsletter, setNewsletter] = useState("");

  const submitNewsletter = () => {
    const value = {email:newsletter};
    sendNewsletterAction(value);
  }
  return(
  <footer className="footer" data-testid="footer">
    <div className="container">
      <div className="copyright-wrap d-md-flex"></div>
      <div className="row footer-top">
        <div className="col-lg-5 col-md-10 footer-column">
          <span className="newsletter-title">Nuestro Newsletter</span>
          <ul>
            <li className='newsletter-text'>Sumate para estar al tanto de todas las ofertas y promociones</li>
            <li>
              <div className='input-button-group'>
                <div className="input-group">
                  <input className="email-input" type="email" placeholder="Escrib&iacute; tu email" id="email" pattern=".+@example\.com" size={30} onChange={(event)=>setNewsletter(event.target.value)}/>
                  <div className="input-group-append ">
                    <button className="btn btn-primary-outline newsletter-btn" type="button" onClick={submitNewsletter}>Sumarme</button>
                  </div>
                </div>
              </div>
            </li>
           {/* <li>Bogota 3188. (Principal)</li>
            <li>Av. Avellaneda 3493.</li>
            <li>Campana 398.</li>
            <li>Flores - CABA - Argentina</li>
            <li>Tel&eacute;fono: +54 9 11 5748-2560  / +54 9 11 3059-6702</li>
            <li>
              <a href="mailto:LenMaguen@gmail.com">
                lenmaguen@gmail.com
              </a>
            </li>*/}
          </ul>
        </div>
        <div className="col-lg-1"></div>
        <div className="col-lg-6 col-md-10 footer-column">
          <div className="row">
            <div className="col-6 col-md-4 footer-column">
              <span className="title">Nosotros</span>
              <ul>
                <li><a></a></li>
                <li><Link to="/contacto">Nuestro local</Link></li>
                <li><Link to="/home">Historia</Link></li>
                <li><Link to="/contacto">Contacto</Link></li>
                <li>
                  <a href="https://instagram.com/lenceriamaguen?igshid=MmJiY2I4NDBkZg==" target="_blank" rel="noopener noreferrer">
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/lenceriamaguen" target="_blank" rel="noopener noreferrer">
                    Facebook
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-8 footer-column">
              <span className="title">Sitemap</span>
              <ul>
              <li><a></a></li>
                <li><Link to="/preguntas-frecuentes">Preguntas frecuentes</Link></li>
                <li><Link to="/precios">Lista de Precios</Link></li>
                <li><Link to="/catalogos">Cat&aacute;logos</Link></li>
                <li><Link to={{pathname: '/productos', state: {oferta:false}}}>Productos</Link></li>
                <li><Link to={{pathname: '/productos', state: {oferta:false}}}>Categor&iacute;as</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright col-lg-12">
          &copy; 2024 Derechos reservados por 
          {' '}
          <a href="https://info.jaypionba.com/" target="_blank" rel="noopener noreferrer">Jaypion</a>
        </div>
  </footer>);
};
const mapStateToProps = ()=>({});
const mapDispatchToProps = (dispatch) => ({
  sendNewsletterAction: bindActionCreators(sendNewsletter, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
