import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './Modal.scss';

const Modal = (props) => {
  const {
    children, isLarge, loading, onClose,
  } = props;

  const closeModal = () => {
    if (!loading) {
      onClose();
    }
  };

  const handleKeyUp = (event) => {
    // 27: Escape key
    /* if (event.keyCode === 27) {
      closeModal();
    } */
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  const renderCloseButton = () => (
    <div>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        onClick={closeModal}
        data-testid="close-modal-button"
      >
        &times;
      </button>
    </div>
  );

  return (
    <div className="modal" role="dialog" data-testid="modal">
      <div className="modal-overlay" role="presentation" onClick={closeModal} />
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className={`modal-dialog ${isLarge ? 'modal-lg' : ''}`} data-testid="modal-dialog">
            <div className="modal-content">
              {!loading && renderCloseButton()}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  isLarge: false,
  loading: false,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isLarge: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
