import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Prices from 'views/Prices';

const PricesContainer = (props) => {
  const {
    prices,
  } = props;
  return (
    <Prices
      prices={prices}
    />
  );
};

const mapStateToProps = (state) => ({
  prices: state.app.prices,
});

PricesContainer.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    pdf: PropTypes.string,
  })).isRequired,
};

export default connect(mapStateToProps)(PricesContainer);
