import React from 'react';
import ScrollMenu from 'react-horizontal-scroll-menu';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { categoriesArrayPropTypes } from 'helpers/propTypes';
import Menu from 'components/main/Header/Menu';
import Logo from 'components/main/Header/Logo';
import heartImage from './assets/heart.svg';
import useWindowSize from '../../../hooks/useWindowSize';

import './Navbar.scss';

const Navbar = (props) => {
  const {
    cartSize, categories, closeMenu, isMenuActive, isUserLoggedIn, openMenu, openSearchModal, handleClick,
    favouriteSize,
  } = props;
  const size = useWindowSize()
  const renderResponsiveMenu = () => (
    <>
      <div
        className={`fs-menu-overlay${isMenuActive ? ' pointer-active' : ''}`}
        role="presentation"
        onClick={closeMenu}
      />
      <div className={`hamburger-menu${isMenuActive ? ' active' : ''}`}>
        <div
          className="hamburger-close"
          role="presentation"
          onClick={closeMenu}
        >
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        <div className="hamburger-menu-content">
          <Menu
            categories={categories}
            closeMenu={closeMenu}
            isMobile
            isUserLoggedIn={isUserLoggedIn}
            ulClassName="menu-top-nav"
          />
        </div>
      </div>
    </>
  );

  return (
    <div data-testid="navbar">
      <div className="main-nav-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 p-0">
              <nav className="navbar">
                <div className="navbar-content">
                  <div className="navbar-logo">
                  {size.width > 991 ? <></> : <Logo/>}
                  </div>
                  <Menu
                    categories={categories}
                    closeMenu={closeMenu}
                    isUserLoggedIn={isUserLoggedIn}
                    ulClassName="navbar-menu"
                    handleClick={handleClick}
                  />
                    <ul className="navbar-user p-0">
                      <li>
                        <div className="navbar-icon" role="presentation" onClick={openSearchModal}>
                          <i className="fa fa-search" aria-hidden="true" />
                        </div>
                      </li>
                      <li>
                        <Link to="/carrito">
                          <div className="navbar-icon">
                            <i className="fa fa-shopping-cart" aria-hidden="true" />
                            <span id="checkout-items" className="checkout-items">
                              {cartSize}
                            </span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                    <div
                      className="hamburger-container"
                      role="presentation"
                      onClick={openMenu}
                    >
                      <i className="fa fa-bars" aria-hidden="true" />
                    </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {renderResponsiveMenu()}
    </div>
  );
};

Navbar.propTypes = {
  cartSize: PropTypes.number.isRequired,
  categories: categoriesArrayPropTypes.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isMenuActive: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  favouriteSize: PropTypes.number.isRequired,
  openMenu: PropTypes.func.isRequired,
  openSearchModal: PropTypes.func.isRequired,
};

export default Navbar;
