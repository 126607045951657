export const PRODUCT_GET_ALL_REQUESTED = 'PRODUCT_GET_ALL_REQUESTED';
export const PRODUCT_GET_ALL_SUCCEEDED = 'PRODUCT_GET_ALL_SUCCEEDED';
export const PRODUCT_GET_ALL_FAILED = 'PRODUCT_GET_ALL_FAILED';

export const PRODUCT_GET_MAX_PRICE_REQUESTED = 'PRODUCT_GET_MAX_PRICE_REQUESTED';
export const PRODUCT_GET_MAX_PRICE_SUCCEEDED = 'PRODUCT_GET_MAX_PRICE_SUCCEEDED';
export const PRODUCT_GET_MAX_PRICE_FAILED = 'PRODUCT_GET_MAX_PRICE_FAILED';

export const PRODUCT_GET_MOST_SELLED_REQUESTED = 'PRODUCT_GET_MOST_SELLED_REQUESTED';
export const PRODUCT_GET_MOST_SELLED_SUCCEEDED = 'PRODUCT_GET_MOST_SELLED_SUCCEEDED';
export const PRODUCT_GET_MOST_SELLED_FAILED = 'PRODUCT_GET_MOST_SELLED_FAILED';

export const PRODUCT_GET_NEW_ARRIVALS_REQUESTED = 'PRODUCT_GET_NEW_ARRIVALS_REQUESTED';
export const PRODUCT_GET_NEW_ARRIVALS_SUCCEEDED = 'PRODUCT_GET_NEW_ARRIVALS_SUCCEEDED';
export const PRODUCT_GET_NEW_ARRIVALS_FAILED = 'PRODUCT_GET_NEW_ARRIVALS_FAILED';

export const PRODUCT_GET_OFFERS_REQUESTED = 'PRODUCT_GET_OFFERS_REQUESTED';
export const PRODUCT_GET_OFFERS_SUCCEEDED = 'PRODUCT_GET_OFFERS_SUCCEEDED';
export const PRODUCT_GET_OFFERS_FAILED = 'PRODUCT_GET_OFFERS_FAILED';
