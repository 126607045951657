import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import { createLogger } from 'redux-logger';

import reducers from 'redux/reducers';
import rootSaga from 'redux/sagas';

import formMiddleware from 'redux/middlewares/form.middleware';
import notificationMiddleware from 'redux/middlewares/notification.middleware';
import redirectMiddleware from 'redux/middlewares/redirect.middleware';
import storageMiddleware from 'redux/middlewares/storage.middleware';
import fileMiddleware from './middlewares/files.middleware';

const sagaMiddleware = createSagaMiddleware();
// const loggerMiddleware = createLogger();

const store = createStore(
  reducers,
  applyMiddleware(
    sagaMiddleware,
    formMiddleware,
    notificationMiddleware,
    redirectMiddleware,
    storageMiddleware,
    fileMiddleware,
    // loggerMiddleware,
  ),
);

sagaMiddleware.run(rootSaga);

export default store;
