import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Collapse } from '@material-ui/core';

import Breadcrumb from 'components/commons/Breadcrumb';

import './FrequentQuestions.scss';

const FrequentQuestions = () => {
  const [openHowToBuy, setOpenHowToBuy] = useState(false);
  const [openShipping, setOpenShipping] = useState(false);
  const [openShippingRate, setOpenShippingRate] = useState(false);
  const [openHowToPay, setOpenHowToPay] = useState(false);
  const [openShippingRatePayment, setOpenShippingRatePayment] = useState(false);
  const [openOrderArrive, setOpenOrderArrive] = useState(false);

  return (
    <div className="container" data-testid="faq-page">
      <div className="row">
        <div className="col clearfix">
          <Breadcrumb urls={['/preguntas-frecuentes']} names={['Preguntas frecuentes']} />
          <div className="jumbotron" style={{ backgroundColor: 'white' }}>
            <h3>Preguntas frecuentes</h3>
            <p>
              Para obtener una respuesta inmediata, podés entrar a las siguientes preguntas
              {' '}
              frecuentes, donde encontrarás información sobre las cuestiones más consultadas:
            </p>
          </div>
          <div className="jumbotron" style={{ marginTop: '-60px' }}>
            <h4>
              ¿TIENEN NEGOCIO A LA CALLE?
              <ExpandMoreIcon
                style={{ fontSize: '36px', position: 'relative', top: '10px' }}
                onClick={() => setOpenHowToBuy(!openHowToBuy)}
                aria-expanded={openHowToBuy}
                data-testid="expand-how-to-buy"
              />
            </h4>
            <Collapse in={openHowToBuy}>
              <div id="example-collapse-text">
                <ol style={{ marginLeft: '18px' }}>
                  <li>
                    Nuestros locales estan ubicados en Bogota 3188, Av. Avellaneda 3493, Campana 398, Flores, CABA.
                  </li>
                  <li>Nuestro horario de atención es de lunes a viernes de 8 a 18hs. </li>
                </ol>
              </div>
            </Collapse>
          </div>
          <div className="jumbotron">
            <h4>
              ¿ME TENGO QUE REGISTAR PARA PODER HACER UNA COMPRA?
              <ExpandMoreIcon
                style={{ fontSize: '36px', position: 'relative', top: '10px' }}
                onClick={() => setOpenShipping(!openShipping)}
                aria-expanded={openShipping}
                data-testid="expand-shipping"
              />
            </h4>
            <Collapse in={openShipping}>
              <div id="example-collapse-text">
                <ol style={{ marginLeft: '18px' }}>
                  <li>
                    No es necesario que te registres para hacer el pedido. Aunque luego de
                    {' '}
                    realizarlo vas a tener que poner tus datos de contacto para que podamos
                    {' '}
                    coordinar el pago y el envío.
                  </li>
                </ol>
              </div>
            </Collapse>
          </div>
          <div className="jumbotron">
            <h4>
              ¿PUEDO COMPRAR PRODUCTOS POR MENOS DEL MONTO M&Iacute;NIMO?
              <ExpandMoreIcon
                style={{ fontSize: '36px', position: 'relative', top: '10px' }}
                onClick={() => setOpenShippingRate(!openShippingRate)}
                aria-expanded={openShippingRate}
                data-testid="expand-shipping-rate"
              />
            </h4>
            <Collapse in={openShippingRate}>
              <div id="example-collapse-text">
                <ol style={{ marginLeft: '18px' }}>
                  <li>
                    Las compras son ÚNICAMENTE por mayor.
                    {' '}
                    Tenés un monto de compra mínima obligatorio.
                    {' '}
                  </li>
                </ol>
              </div>
            </Collapse>
          </div>
          <div className="jumbotron">
            <h4>
              ¿C&Oacute;MO ABONO EL PEDIDO?
              <ExpandMoreIcon
                style={{ fontSize: '36px', position: 'relative', top: '10px' }}
                onClick={() => setOpenHowToPay(!openHowToPay)}
                aria-expanded={openHowToPay}
                data-testid="expand-how-to-pay"
              />
            </h4>
            <Collapse in={openHowToPay}>
              <div id="example-collapse-text">
                <ol style={{ marginLeft: '18px' }}>
                  <li>
                    Cuando recibimos el pedido en el rango de 24hs
                    {' '}
                    te confirmamos el stock y te pasamos el medio de pago (Transferencia, Mercado pago, efectivo).
                  </li>
                </ol>
              </div>
            </Collapse>
          </div>
          <div className="jumbotron">
            <h4>
              ¿QUÉ PASA SI DENTRO DEL PEDIDO RECIBÍ ALGÚN PRODUCTO ROTO?
              <ExpandMoreIcon
                style={{ fontSize: '36px', position: 'relative', top: '10px' }}
                onClick={() => setOpenShippingRatePayment(!openShippingRatePayment)}
                aria-expanded={openShippingRatePayment}
                data-testid="expand-shipping-rate-payment"
              />
            </h4>
            <Collapse in={openShippingRatePayment}>
              <div id="example-collapse-text">
                <ol style={{ marginLeft: '18px' }}>
                  <li>
                    No te preocupes. Contamos con un equipo especializado en reclamos y
                    {' '}
                    devoluciones. Nos vas a poder contactar en todo momento y te vamos
                    {' '}
                    a asesorar para solucionar el problema y que quedes conforme con tu compra.
                  </li>
                </ol>
              </div>
            </Collapse>
          </div>
          <div className="jumbotron">
            <h4>
              ¿SI TENGO ALGUNA OTRA DUDA, PUEDO CONTACTARLOS?
              <ExpandMoreIcon
                style={{ fontSize: '36px', position: 'relative', top: '10px' }}
                onClick={() => setOpenOrderArrive(!openOrderArrive)}
                aria-expanded={openOrderArrive}
                data-testid="expand-order-arrive"
              />
            </h4>
            <Collapse in={openOrderArrive}>
              <div id="example-collapse-text">
                <ol style={{ marginLeft: '18px' }}>
                  <li>
                    Sí, podés.
                    {' '}
                    <dd>- Mandanos un Whatsapp al  +54 9 11 5748-2560  / +54 9 11 3059-6702</dd>
                    <dd>- Envianos un mail a lenmaguen@gmail.com</dd>
                  </li>
                </ol>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrequentQuestions;
