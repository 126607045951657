import { get } from 'services/api';

export const getAll = async () => {
  const response = await get('categories');
  return response;
};

export const getCategoriesHome = async () => {
  const response = await get('categories/categoryHome');
  return response;
};

export default { getAll };
