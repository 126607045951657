import React from 'react';
import PropTypes from 'prop-types';

import { userPropTypes } from 'helpers/propTypes';

import Breadcrumb from 'components/commons/Breadcrumb';
import Loading from 'components/commons/Loading';
import ProfileForm from 'components/forms/ProfileForm';
import PasswordForm from 'components/forms/PasswordForm';
import Benefit from 'components/commons/Benefit';

import './Profile.scss';

const Profile = (props) => {
  const {
    changePassword, loading, minimumPurchase, updateProfile, user,
  } = props;

  const renderForms = () => (
    <>
      <div className="col-lg-6 user-form">
        <ProfileForm
          onSubmit={updateProfile}
          user={user}
        />
      </div>
      <div className="col-lg-6 user-form">
        <PasswordForm
          onSubmit={changePassword}
        />
      </div>
    </>
  );

  const renderLoading = () => (
    <div className="profile-loading">
      <Loading message="Cargando..." size={80} />
    </div>
  );

  return (
    <div data-testid="profile-page">
      <div className="container">
        <div className="row">
          <div className="col clearfix">
            <Breadcrumb urls={['/perfil']} names={['Perfil']} />
            <div className="row">
              {loading && renderLoading()}
              {!loading && renderForms()}
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <Benefit minimumPurchase={minimumPurchase} />
      </div>
    </div>
  );
};

Profile.defaultProps = {
  user: null,
};

Profile.propTypes = {
  changePassword: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  updateProfile: PropTypes.func.isRequired,
  user: userPropTypes,
};

export default Profile;
