import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Avatar from '@material-ui/core/Avatar';
import Lock from '@material-ui/icons/Lock';

import Input from 'components/commons/Input';
import Button from 'components/commons/Button';

import './PasswordForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.current_password) {
    errors.current_password = 'Contraseña actual inválida';
  } else if (values.current_password.length < 8) {
    errors.current_password = 'Mínimo 8 caracteres';
  }
  if (!values.password) {
    errors.password = 'Contraseña nueva inválida';
  } else if (values.password.length < 8) {
    errors.password = 'Mínimo 8 caracteres';
  } else if (values.password === values.current_password) {
    errors.password = 'La contraseña nueva debe ser diferente a la actual';
  }
  if (!values.password_confirmation) {
    errors.password_confirmation = 'Contraseña nueva inválida';
  } else if (values.password_confirmation !== values.password) {
    errors.password_confirmation = 'Las contraseñas nuevas no coinciden';
  }
  return errors;
};

const PasswordForm = (props) => {
  const {
    handleSubmit, onSubmit, submitting,
  } = props;

  return (
    <div className="container" data-testid="password-form">
      <div className="row">
        <div className="form-container">
          <Avatar className="avatar">
            <Lock />
          </Avatar>
          <h4>Actualizar contraseña</h4>
          <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
            <Field
              component={Input}
              label="Contraseña actual"
              name="current_password"
              type="password"
            />
            <Field
              component={Input}
              label="Contraseña nueva"
              name="password"
              type="password"
            />
            <Field
              component={Input}
              label="Confirmar contraseña nueva"
              name="password_confirmation"
              type="password"
            />
            <Button text="Enviar" type="submit" buttonClass="btn-block" disabled={submitting} />
          </form>
        </div>
      </div>
    </div>
  );
};

PasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'password',
  validate,
})(PasswordForm);
