import { createSelector } from 'reselect';

const getCategories = (state) => state.category.items;
const getCategoryCode = (state, props) => props.match.params.category;

export const categoriesSelector = createSelector([getCategories], (categories) => categories);

export const categorySelector = createSelector([getCategories, getCategoryCode],
  (categories, code) => (
    categories.find((category) => category.code === code, null)
  ));
