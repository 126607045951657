export const CART_UPDATE_REQUESTED = 'CART_UPDATE_REQUESTED';
export const CART_UPDATE_SUCCEEDED = 'CART_UPDATE_SUCCEEDED';

export const CART_CHECKOUT_PICKUP_REQUESTED = 'CART_CHECKOUT_PICKUP_REQUESTED';
export const CART_CHECKOUT_DELIVERY_REQUESTED = 'CART_CHECKOUT_DELIVERY_REQUESTED';
export const CART_CHECKOUT_SUCCEEDED = 'CART_CHECKOUT_SUCCEEDED';
export const CART_CHECKOUT_FAILED = 'CART_CHECKOUT_FAILED';

export const CART_COUPON_REQUESTED = 'CART_COUPON_REQUESTED';
export const CART_COUPON_SUCCEEDED = 'CART_COUPON_SUCCEEDED';
export const CART_COUPON_FAILED = 'CART_COUPON_FAILED';

export const CART_COUPON_DELETE = 'CART_COUPON_DELETE';
